import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { getResourceQueryParam } from '../../utils/queryParams/queryParams';
import { getLocationId } from '../../utils/getLocationId/getLocationId';
import {
  DialogType,
  ServicePreferencesDialogViewModel,
} from '../../viewModel/dialogViewModel/dialogViewModel';
import { PreselectedValues } from '@wix/bookings-services-preferences-modal/types';
import { EnrichedService } from '../../types/types';
import { ServiceListContext } from '../../context/createServiceListContext';

export type openServicesPreferencesModalAction = ({
  service,
}: {
  service: EnrichedService;
}) => Promise<void>;

export const createOpenServicesPreferencesModalAction = ({
  flowAPI,
  widgetViewModel,
  setProps,
  serviceListContext,
}: {
  flowAPI: ControllerFlowAPI;
  widgetViewModel: WidgetViewModel;
  serviceListContext: ServiceListContext;
  setProps: Function;
}): openServicesPreferencesModalAction => {
  const { businessInfo } = serviceListContext;

  return async ({ service }) => {
    const { createModalData } = await import(
      '@wix/bookings-services-preferences-modal/utils'
    );
    const { enrichModalData } = await import(
      '@wix/bookings-services-preferences-modal/controllerActions'
    );

    const queryParam = getResourceQueryParam(
      flowAPI.controllerConfig.wixCodeApi,
    );
    const locationId = getLocationId(flowAPI, widgetViewModel);
    const preselectedValues: PreselectedValues = {
      locationId,
      ...(queryParam.resource
        ? {
            resources: {
              staff: [queryParam.resource],
            },
          }
        : {}),
    };

    const modalData = createModalData({
      regionalSettingsLocale: businessInfo.regionalSettings,
      service,
      preselectedValues,
    });

    const servicePreferencesDialogViewModel: ServicePreferencesDialogViewModel =
      {
        isOpen: true,
        type: DialogType.ServicePreferences,
        data: modalData,
      };

    widgetViewModel.dialogViewModel = servicePreferencesDialogViewModel;

    setProps({
      widgetViewModel: {
        ...widgetViewModel,
      },
    });

    enrichModalData({
      service,
      httpClient: flowAPI.httpClient,
      getServicesPreferencesModalData: () =>
        servicePreferencesDialogViewModel.data,
      setServicesPreferencesModalData: (data) => {
        servicePreferencesDialogViewModel.data = data;
        setProps({ widgetViewModel: { ...widgetViewModel } });
      },
      experiments: flowAPI.experiments,
    });
  };
};
