import { ReferralInfo } from '../../utils/bi/consts';
import { NavigateToNextPageAction } from '../navigateToNextPage/navigateToNextPage';
import { OnBookFlowStartAttemptAction } from '../onBookFlowStartAttempt/onBookFlowStartAttempt';
import { NavigationTarget } from '../navigateToNextPage/consts';
import { EnrichedService } from '../../types/types';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';

export type OnActionButtonClickAction = ({
  service,
  referralInfo,
}: {
  service: EnrichedService;
  referralInfo: ReferralInfo;
}) => Promise<void>;

export const createOnActionButtonClickAction = ({
  navigateToNextPage,
  onBookFlowStartAttempt,
}: {
  navigateToNextPage: NavigateToNextPageAction;
  onBookFlowStartAttempt: OnBookFlowStartAttemptAction;
}): OnActionButtonClickAction => {
  return async ({ service, referralInfo }) => {
    if (service.type === ServiceType.COURSE || service.displayOnlyNoBookFlow) {
      return navigateToNextPage({
        target: NavigationTarget.SERVICE_PAGE,
        service,
        referralInfo,
      });
    } else {
      return onBookFlowStartAttempt({ service, referralInfo });
    }
  };
};
