import {
  GetBusinessResponse,
  Property,
} from '@wix/ambassador-services-catalog-server/types';
import { TimezoneType } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getUoULocale } from '../utils/locale/locale';
import { BusinessInfo } from '../../legacy/types';
import { isAnywhereFlow } from '../utils/anywhere/anywhere';

export type ServiceListContext = {
  isMultiServiceAppointmentEnabled: boolean;
  isPricingPlanInstalled: boolean;
  isAnywhereFlow: boolean;
  businessInfo: BusinessInfo;
};

type CreateServiceListContextParams = {
  getBusinessResponse: GetBusinessResponse;
  flowAPI: ControllerFlowAPI;
  isPricingPlanInstalled: boolean;
  isMultiServiceAppointmentEnabled?: never;
  businessInfo?: never;
};

type CreateServiceListContextFromDeprecatedParams = {
  getBusinessResponse?: never;
  flowAPI: ControllerFlowAPI;
  isPricingPlanInstalled: boolean;
  isMultiServiceAppointmentEnabled: boolean;
  businessInfo: BusinessInfo;
};

export const createServiceListContext = async ({
  flowAPI,
  getBusinessResponse,
  isPricingPlanInstalled,
  businessInfo: businessInfoParam,
  isMultiServiceAppointmentEnabled: isMultiServiceAppointmentEnabledParam,
}:
  | CreateServiceListContextFromDeprecatedParams
  | CreateServiceListContextParams): Promise<ServiceListContext> => {
  const {
    experiments,
    environment: { isPreview },
    controllerConfig: { wixCodeApi },
  } = flowAPI;
  const businessInfo = getBusinessResponse
    ? mapGetBusinessResponseToBusinessInfo(getBusinessResponse)
    : businessInfoParam;

  const isMultiServiceAppointmentEnabled = getBusinessResponse
    ? experiments.enabled('specs.bookings.multiServiceAppointment') &&
      getBusinessResponse.businessProperties?.customProperties?.find(
        (businessProperty) =>
          businessProperty.propertyName === 'isMultiServicesAppointmentsEnable',
      )?.value === 'true'
    : experiments.enabled('specs.bookings.multiServiceAppointment') &&
      isMultiServiceAppointmentEnabledParam;

  const isAnywhereFlowInd = await isAnywhereFlow(wixCodeApi, isPreview);

  return {
    isMultiServiceAppointmentEnabled,
    isPricingPlanInstalled,
    isAnywhereFlow: isAnywhereFlowInd,
    businessInfo,
  };
};

const mapGetBusinessResponseToBusinessInfo = (
  getBusinessResponse: GetBusinessResponse,
): BusinessInfo => {
  const { info } = getBusinessResponse;
  const { name, language, timeZone, locale } = info!;

  return {
    language: getUoULocale(language || ''),
    name: name || '',
    regionalSettings: locale!,
    timeZone: timeZone!,
    timezoneProperties: {
      defaultTimezone: getDefaultTimezoneType(
        getBusinessResponse.businessProperties?.customProperties!,
      ),
    },
  };
};

const getDefaultTimezoneType = (customProperties: Property[]) => {
  return (
    customProperties?.find(
      (businessProperty) => businessProperty.propertyName === 'defaultTimezone',
    )?.value || TimezoneType.BUSINESS
  );
};
