import { ALL_SERVICES } from '../../consts';
import {
  FilterOption,
  FilterServicesByOptions,
  EnrichedService,
} from '../../types/types';
import settingsParams from '../../components/BookOnline/settingsParams';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getBlockNavigationReason } from './getBlockNavigationReason/getBlockNavigationReason';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import { bulkCreateAnywhereUrl } from '@wix/wix-anywhere-api';
import {
  Category,
  Location,
  LocationType,
  Service,
  ServiceType,
  Payment,
  RateType,
  Schedule,
  URLs,
  StaffMember,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { ServiceListSettings } from '../../../legacy/appSettings/appSettings';
import { buildQueryServicesFilter } from '../filters/buildQueryServicesFilter';
import {
  CatalogServiceDto,
  PaymentType as PaymentTypeV1,
  PricingPlanInfo,
  ReservedLocationIds,
  ServiceLocation,
  ServiceLocationType,
  ServicePaymentDto,
  ServiceScheduleHeaderDto,
  ServiceType as ServiceTypeV1,
  StaffMember as StaffMemberV1,
} from '@wix/bookings-uou-types';

export const filterServicesBySelectedTab = ({
  filterOptions,
  services,
  settings,
}: {
  filterOptions: FilterOption[];
  services: EnrichedService[];
  settings: any;
}): EnrichedService[] => {
  if (!services.length) {
    return services;
  }

  const selectedFilterId = filterOptions.find(
    ({ isSelected }: FilterOption) => isSelected,
  )?.id;

  if (
    !selectedFilterId ||
    selectedFilterId === ALL_SERVICES ||
    !settings.get(settingsParams.isListFilterVisible)
  ) {
    return services;
  }

  const filterServicesBy = settings.get(settingsParams.filterServicesBy);
  if (filterServicesBy === FilterServicesByOptions.CATEGORIES) {
    return filterServicesByCategoryId(services, selectedFilterId);
  }
  return filterServicesByLocationId(services, selectedFilterId);
};

export const filterServicesByCategoryId = (
  services: EnrichedService[],
  categoryId: string,
) => {
  return services.filter((service) => service.category?.id === categoryId);
};

export const filterServicesByLocationId = (
  services: EnrichedService[],
  locationId: string,
) => {
  if (locationId === ReservedLocationIds.OTHER_LOCATIONS) {
    return services.filter((service) =>
      service.locations?.find(
        (location) => location.type !== LocationType.BUSINESS,
      ),
    );
  }
  return services.filter((service) =>
    service.locations?.find((location) => location.business?.id === locationId),
  );
};

export const enrichServices = ({
  services,
  isAnywhereFlow = false,
  flowAPI,
  activeFeatures,
  isPricingPlanInstalled,
}: {
  services: Service[];
  flowAPI: ControllerFlowAPI;
  isAnywhereFlow?: boolean;
  isPricingPlanInstalled: boolean;
  activeFeatures: GetActiveFeaturesResponse;
}): Promise<EnrichedService[]> =>
  Promise.all(
    services.map(async (service) => {
      const enrichedService: EnrichedService = service as any;

      if (isAnywhereFlow) {
        const anywhereURL = await bulkCreateAnywhereUrl({
          urls: [service.urls?.servicePage?.relativePath!],
          baseUrl: service.urls?.servicePage?.url!.replace(
            service.urls?.servicePage?.relativePath!,
            '',
          )!,
        });
        service.urls!.servicePage!.url = anywhereURL[0];
      }

      const { firstSessionStart, lastSessionEnd } = enrichedService.schedule!;

      const isPassedStartDate =
        !firstSessionStart || firstSessionStart < new Date();
      const isPassedEndDate =
        service.type === ServiceType.COURSE &&
        (!lastSessionEnd || lastSessionEnd < new Date());

      enrichedService.displayOnlyNoBookFlow =
        (isPassedStartDate && isPassedEndDate) ||
        !service.onlineBooking?.enabled;

      enrichedService.blockNavigationReason = getBlockNavigationReason({
        service: enrichedService,
        flowAPI,
        isPricingPlanInstalled,
        activeFeatures,
      });

      return enrichedService;
    }),
  );

export const getCategoriesFromServices = (
  services: EnrichedService[],
): Category[] => {
  const categoryIdCategoryMap = new Map<string, Category>();

  services.forEach(({ category }) => {
    categoryIdCategoryMap.set(category?.id!, category!);
  });

  return Array.from(categoryIdCategoryMap.values()).sort(
    (category1, category2) => category1?.sortOrder! - category2?.sortOrder!,
  );
};

export const getBusinessLocationsFromServices = (
  services: EnrichedService[],
): Location[] => {
  const locationIdLocationMap = new Map<string, Location>();

  services.forEach(({ locations }) => {
    locations?.forEach((location) => {
      if (location.type === LocationType.BUSINESS) {
        locationIdLocationMap.set(location?.business?.id!, location);
      }
    });
  });

  return Array.from(locationIdLocationMap.values());
};

export const isServiceV2 = (
  service: Service | CatalogServiceDto,
): service is Service => !!(service as Service).bookingPolicy;

export const filterServicesBySettings = <ServiceArg extends Service>({
  flowAPI,
  services,
  shouldWorkWithAppSettings,
  appSettings,
}: {
  services: ServiceArg[];
  shouldWorkWithAppSettings: boolean;
  appSettings?: ServiceListSettings;
  flowAPI: ControllerFlowAPI;
}) => {
  const { categoryIds, limit, locationIds, serviceIds, staffMemberIds } =
    buildQueryServicesFilter({
      flowAPI,
      shouldWorkWithAppSettings,
      appSettings,
    });

  if (categoryIds) {
    services = services.filter(({ category }) =>
      categoryIds.includes(category?.id!),
    );
  }

  if (locationIds) {
    services = services.filter(({ locations }) => {
      if (locations?.[0]?.type !== LocationType.BUSINESS) {
        return locationIds.includes(ReservedLocationIds.OTHER_LOCATIONS);
      }

      return locations.some(({ business }) =>
        locationIds.includes(business?.id!),
      );
    });
  }

  if (serviceIds) {
    services = services.filter(({ id }) => serviceIds.includes(id!));
  }

  if (staffMemberIds) {
    services = services.filter(({ staffMemberIds: serviceStaffMemberIds }) => {
      return staffMemberIds?.some((id) => serviceStaffMemberIds?.includes(id));
    });
  }

  if (limit) {
    services = services.slice(0, limit);
  }

  return services;
};

export const mapServiceTypeV1ToV2 = (
  serviceType: ServiceTypeV1,
): ServiceType => {
  switch (serviceType) {
    case ServiceTypeV1.INDIVIDUAL:
      return ServiceType.APPOINTMENT;
    case ServiceTypeV1.GROUP:
      return ServiceType.CLASS;
    case ServiceTypeV1.COURSE:
      return ServiceType.COURSE;
  }
};
const mapPaymentRateType = (payment: ServicePaymentDto): RateType => {
  if (payment.priceText) {
    return RateType.CUSTOM;
  } else if (payment.isFree || !payment.price) {
    return RateType.NO_FEE;
  } else if (payment.isVariedPricing) {
    return RateType.VARIED;
  } else {
    return RateType.FIXED;
  }
};
const mapPaymentToPaymentV2 = (
  payment: ServicePaymentDto,
  pricingPlanInfo: PricingPlanInfo,
): Payment => {
  const rateType = mapPaymentRateType(payment);
  const paymentV2: Payment = {
    rateType,
    pricingPlanIds: pricingPlanInfo.pricingPlans.map((plan) => plan.id),
  };
  if (rateType === RateType.FIXED) {
    paymentV2.fixed = {
      price: { value: `${payment.price}`, currency: payment.currency },
    };
  } else if (RateType.CUSTOM === rateType) {
    paymentV2.custom = {
      description: payment.priceText,
    };
  } else if (RateType.VARIED === rateType) {
    paymentV2.varied = {
      defaultPrice: { value: `${payment.price}`, currency: payment.currency },
      minPrice: {
        value: `${payment.minPrice?.price}`,
        currency: payment.minPrice?.currency,
      },
      maxPrice: {
        value: `${payment.maxPrice?.price}`,
        currency: payment.maxPrice?.currency,
      },
    };
  }
  paymentV2.options = {
    online: [PaymentTypeV1.ONLINE || PaymentTypeV1.BOTH].includes(
      payment.paymentType,
    ),
    inPerson: [PaymentTypeV1.OFFLINE || PaymentTypeV1.BOTH].includes(
      payment.paymentType,
    ),
  };
  return paymentV2;
};
const mapLocationType = (location: ServiceLocationType): LocationType => {
  switch (location) {
    case ServiceLocationType.OWNER_BUSINESS:
      return LocationType.BUSINESS;
    case ServiceLocationType.OWNER_CUSTOM:
      return LocationType.CUSTOM;
    case ServiceLocationType.CUSTOM:
      return LocationType.CUSTOMER;
    case ServiceLocationType.CLIENT_PLACE:
      return LocationType.CUSTOMER;
    default:
      return LocationType.BUSINESS;
  }
};
const mapLocationsToLocationV2 = (
  locations?: ServiceLocation[],
): Location[] | undefined => {
  return locations?.map((location: ServiceLocation) => {
    return {
      type: mapLocationType(location.type),
      business: {
        id: location.businessLocation?.id,
        name: location.businessLocation?.name,
        default: location.businessLocation?.default,
        address: location.businessLocation?.address,
      },
      custom: {
        address: {
          formattedAddress: location.address || location.locationText,
        },
      },
      calculatedAddress: {
        formattedAddress: location.address || location.locationText,
      },
    };
  });
};

const mapScheduleToScheduleV2 = (
  scheduleHeader: ServiceScheduleHeaderDto,
): Schedule => {
  return {
    firstSessionStart: new Date(
      scheduleHeader.startDateAsUTC || scheduleHeader.startDate,
    ),
    lastSessionEnd: new Date(scheduleHeader.endDate),
    availabilityConstraints: {
      sessionDurations: scheduleHeader.durationsInMinutes || [],
    },
  };
};

const mapStaffMemberToStaffMemberV2 = (
  staffMembers?: StaffMemberV1[],
): StaffMember[] => {
  if (!staffMembers) {
    return [];
  }
  return staffMembers?.map((staffMember) => {
    return {
      staffMemberId: staffMember.id,
      name: staffMember.name,
    };
  });
};

export const mapServiceToServiceV2 = ({
  service,
}: {
  service: CatalogServiceDto;
}): Service => ({
  id: service.id,
  name: service.info.name,
  tagLine: service.info.tagLine,
  description: service.info.description,
  conferencing: {
    enabled: service.schedulePolicy.includeConferenceOption,
  },
  type: mapServiceTypeV1ToV2(service.type),
  sortOrder: service.order,
  category: service.info.category,
  payment: mapPaymentToPaymentV2(service.payment, service.pricingPlanInfo),
  locations: mapLocationsToLocationV2(service.info.locations),
  schedule: mapScheduleToScheduleV2(service.scheduleHeader),
  mainSlug: { name: service.urlName },
  staffMembers: mapStaffMemberToStaffMemberV2(service.staffMembers),
  urls: mapServiceUrls(service),
  onlineBooking: mapServiceOnlineBookingToV2(service),
  bookingPolicy: {
    bookAfterStartPolicy: {
      enabled: service.schedulePolicy?.bookUpToXMinutesBefore === undefined,
    },
  },
  media: mapServiceMedia(service),
});

const mapServiceUrls = (service: CatalogServiceDto): URLs => ({
  servicePage: {
    url: service.fullUrl,
  },
});

const mapServiceMedia = (service: CatalogServiceDto): Service['media'] => {
  const { mainMedia } = service.info?.media || {};

  if (!mainMedia) {
    return {};
  }

  return {
    mainMedia: {
      image: {
        altText: mainMedia.altText,
        filename: mainMedia.fileName,
        height: mainMedia.height,
        url: mainMedia.relativeUri,
        width: mainMedia.width,
      },
    },
  };
};

export const isServiceDynamicPricing = (service: Service): boolean =>
  service.payment?.rateType === RateType.VARIED;

const mapServiceOnlineBookingToV2 = (
  service: CatalogServiceDto,
): Service['onlineBooking'] => {
  return {
    requireManualApproval: service.schedulePolicy.isPendingApprovalFlow,
    enabled: !service.schedulePolicy.displayOnlyNoBookFlow,
  };
};
