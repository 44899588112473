import { IStylesParamsNumbersKeys } from '../../src/components/BookOnline/stylesParams';
import { MobileSettingsKeys, SettingsKeys } from '../types';
import { cleanNulls } from '../utils';
import { ServiceListSettings } from './appSettings';
import { ISettingsParamsNumbersKeys } from '../../src/components/BookOnline/settingsParams';

const stylesParamNumberKeyToAppSettingsMapper: {
  [key in IStylesParamsNumbersKeys]: SettingsKeys | MobileSettingsKeys;
} = {
  borderWidth: SettingsKeys.BORDER_WIDTH,
  cornerRadius: SettingsKeys.CORNER_RADIUS,
  filterBorderWidth: SettingsKeys.CATEGORY_BORDER_WIDTH,
  [`${IStylesParamsNumbersKeys.filterBorderWidth}▶︎m`]:
    MobileSettingsKeys.MOBILE_CATEGORY_BORDER_WIDTH,
  filterBorderRadius: SettingsKeys.CATEGORY_BORDER_RADIUS,
  filterSpacing: SettingsKeys.CATEGORY_SPACING,
  overlappingSidePadding: SettingsKeys.OVERLAPPING_SIDE_PADDING,
  overlappingVerticalPadding: SettingsKeys.OVERLAPPING_VERTICAL_PADDING,
  stripBorderWidth: SettingsKeys.STRIP_BORDER_WIDTH,
  stripSidePadding: SettingsKeys.STRIP_SIDE_PADDING,
  stripVerticalPadding: SettingsKeys.STRIP_VERTICAL_PADDING,
  imageAndTextRatio: SettingsKeys.IMAGE_AND_TEXT_RATIO,
  serviceDividerWidth: SettingsKeys.DIVIDER_WIDTH,
  [`${IStylesParamsNumbersKeys.serviceDividerWidth}▶︎m`]:
    MobileSettingsKeys.MOBILE_DIVIDER_WIDTH,
  classicSidePadding: SettingsKeys.CLASSIC_SIDE_PADDING,
  classicVerticalPadding: SettingsKeys.CLASSIC_VERTICAL_PADDING,
  gridSidePadding: SettingsKeys.GRID_SIDE_PADDING,
  gridVerticalPadding: SettingsKeys.GRID_VERTICAL_PADDING,
  bookButtonBorderWidth: SettingsKeys.BUTTON_BORDER_WIDTH,
  bookButtonCornerRadius: SettingsKeys.BUTTON_CORNER_RADIUS,
  loadServicesButtonBorderRadius:
    SettingsKeys.LOAD_SERVICES_BUTTON_CORNER_RADIUS,
  loadServicesButtonBorderWidth: SettingsKeys.LOAD_SERVICES_BUTTON_BORDER_WIDTH,
  serviceImageCornerRadius: SettingsKeys.SERVICE_IMAGE_CORNER_RADIUS,
  serviceImagePadding: SettingsKeys.SERVICE_IMAGE_PADDING,
  mobileWidgetTitleFontSize:
    MobileSettingsKeys.MOBILE_MULTI_OFFERINGS_TITLE_FONT_SIZE,
  mobileFilterTitleFontSize: MobileSettingsKeys.MOBILE_CATEGORY_NAME_FONT_SIZE,
  dropdownBorderWidth: MobileSettingsKeys.MOBILE_CATEGORY_BORDER_WIDTH,
  mobileServiceNameFontSize: MobileSettingsKeys.MOBILE_OFFERING_NAME_FONT_SIZE,
  mobileServiceTaglineFontSize:
    MobileSettingsKeys.MOBILE_OFFERING_TAGLINE_FONT_SIZE,
  mobileMoreInfoButtonFontSize:
    MobileSettingsKeys.MOBILE_MORE_INFO_LABEL_FONT_SIZE,
  mobileServiceDetailsFontSize:
    MobileSettingsKeys.MOBILE_OFFERING_DETAILS_FONT_SIZE,
  mobileBookButtonFontSize: MobileSettingsKeys.MOBILE_BUTTON_TEXT_FONT_SIZE,
  mobileExplorePlansFontSize: MobileSettingsKeys.MOBILE_EXPLORE_PLANS_FONT_SIZE,
  [`${IStylesParamsNumbersKeys.borderWidth}▶︎m`]:
    MobileSettingsKeys.MOBILE_BORDER_WIDTH,
  [`${IStylesParamsNumbersKeys.cornerRadius}▶︎m`]:
    MobileSettingsKeys.MOBILE_CORNER_RADIUS,
  [`${IStylesParamsNumbersKeys.classicSidePadding}▶︎m`]:
    MobileSettingsKeys.MOBILE_SIDE_PADDING,
  [`${IStylesParamsNumbersKeys.classicVerticalPadding}▶︎m`]:
    MobileSettingsKeys.MOBILE_VERTICAL_PADDING,
  [`${IStylesParamsNumbersKeys.gridSidePadding}▶︎m`]:
    MobileSettingsKeys.MOBILE_SIDE_PADDING,
  [`${IStylesParamsNumbersKeys.gridVerticalPadding}▶︎m`]:
    MobileSettingsKeys.MOBILE_VERTICAL_PADDING,
  [`${IStylesParamsNumbersKeys.stripVerticalPadding}▶︎m`]:
    MobileSettingsKeys.MOBILE_STRIP_VERTICAL_PADDING,
};

export function mergeAppSettingsNumbersToStyleParamsNumbers(
  appSettings: ServiceListSettings,
  numbers?: { [key: string]: number },
) {
  function mapNumber({
    appSettingsNumber,
    styleParamsNumberKey,
  }: {
    appSettingsNumber?: number;
    styleParamsNumberKey: IStylesParamsNumbersKeys;
  }): number | null {
    if (numbers?.[styleParamsNumberKey]) {
      return numbers[styleParamsNumberKey];
    }
    return typeof appSettingsNumber !== 'undefined'
      ? Number(appSettingsNumber)
      : null;
  }

  const newNumbers: { [key in IStylesParamsNumbersKeys]?: number | null } = {};
  (
    Object.keys(
      stylesParamNumberKeyToAppSettingsMapper,
    ) as IStylesParamsNumbersKeys[]
  ).forEach((styleParamsNumberKey) => {
    newNumbers[styleParamsNumberKey] = mapNumber({
      appSettingsNumber: appSettings[
        stylesParamNumberKeyToAppSettingsMapper[styleParamsNumberKey]
      ] as number,
      styleParamsNumberKey,
    });
  });

  return cleanNulls(newNumbers);
}

const settingsParamsNumbersKeyToAppSettingsMapper: {
  [key in ISettingsParamsNumbersKeys]: SettingsKeys | MobileSettingsKeys;
} = {
  cardsPerRow: SettingsKeys.LAYOUT_CARDS_PER_ROW,
  cardSpacing: SettingsKeys.SPACE_BETWEEN_OFFERINGS,
  gridCardsSpacing: SettingsKeys.CARDS_SPACING,
  [`${ISettingsParamsNumbersKeys.gridCardsSpacing}▶︎m`]:
    MobileSettingsKeys.MOBILE_CARDS_SPACING,
  servicesDividerWidth: SettingsKeys.SERVICE_DIVIDER_WIDTH,
  stripCardSpacing: SettingsKeys.STRIP_SPACE_BETWEEN_OFFERINGS,
};

export function mergeAppSettingsNumbersToSettingsParams(
  appSettings: ServiceListSettings,
): { [p: string]: number } {
  const appSettingsNumbers: {
    [key in ISettingsParamsNumbersKeys]?: number;
  } = {};

  (
    Object.keys(
      settingsParamsNumbersKeyToAppSettingsMapper,
    ) as ISettingsParamsNumbersKeys[]
  ).forEach((settingsParamsNumbersKey) => {
    const appSettingsValue =
      appSettings[
        settingsParamsNumbersKeyToAppSettingsMapper[settingsParamsNumbersKey]
      ];
    if (typeof appSettingsValue !== 'undefined') {
      appSettingsNumbers[settingsParamsNumbersKey] = appSettingsValue as number;
    }
  });

  return cleanNulls(appSettingsNumbers);
}
